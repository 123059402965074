

/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 50px;
    height: 50px;
    right: 36px;
    top: 36px;
}
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #373a47;
    border-radius: 25px;
    width: 36px;
    height: 5px;
  }
  
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #a90000;
  }

  .customBurgerIcon {
    width: 50;
    height: 50;
  }

  .customCrossIcon {
    width: 100px;
    height: 100px;
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    width: 100px;
    height: 100px;
  }
  
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: transparent;
  }


  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: #5b6468;
    padding: 2.5em 1.5em 0;
    font-size: 1.25em;
    box-shadow: 0 10px 27px rgba(0, 0, 0, 0.05);
    text-shadow: .5px .5px .5px #d291af;
    font-weight: 200;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
  }
  
  /* Individual item */
  .bm-item {
    display: inline-block;
    color: #efdad0;
    margin-bottom: 10px;
    text-align: left;
    text-decoration: none;
    transition: color 0.2s;
  }
  
  .bm-item:hover {
    color: #ec3aab;
  }
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(255, 255, 255, 0.1);
  }


  @media (min-width: 767px){

    .bm-burger-button{
      display: none;
    }

  }