@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.navBar {
    background-color: #d291af;
    /* border: 10px solid #efdad0; */
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-family: montserrat;
    padding: 5px 0px;
}

.leftNav {
    background-color: #d291af;

    padding: 0px 10px;
}

/* .centerNav {
    background-color: #d291af;

} */

.centerNav {
    align-self: center;
    background-color: #5b6468;
    background-image: none;
    background-position: 0 90%;
    background-repeat: repeat no-repeat;
    background-size: 4px 3px;
    border-radius: 15px 225px 255px 15px 15px 255px 225px 15px;
    border-style: solid;
    border-width: 2px;
    border-color: #efdad0;
    box-shadow: rgba(0, 0, 0, .2) 15px 28px 25px -18px;
    box-sizing: border-box;
    color: #efdad0;
    cursor: pointer;
    display: inline-block;
    font-family: montserrat;
    font-size: 1rem;
    line-height: 23px;
    outline: none;
    padding: 0rem .75rem;
    text-decoration: none;
    transition: all 235ms ease-in-out;
    border-bottom-left-radius: 15px 255px;
    border-bottom-right-radius: 225px 15px;
    border-top-left-radius: 255px 15px;
    border-top-right-radius: 15px 225px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin: .75em 1em;
  }

  .centerNav:hover {
    box-shadow: rgba(0, 0, 0, .3) 2px 8px 8px -5px;
    transform: translate3d(0, 4px, 0);
  }
  
  .centerNav:focus {
    box-shadow: rgba(0, 0, 0, .3) 2px 8px 4px -6px;
  }

.rightNav {
    background-color: #d291af;

    padding: 0px 10px;
}

img {
    padding-top: 5px;
    padding-bottom: 5px;
}



/* .navButton {
    background-color: #5b6468;
    color: #efdad0;
    border-radius: 5vh;
    box-shadow: 2px 2px black;
    padding: .3em 0.5em;
    font-size: 1.25em;
    margin: 1em;
}

button:hover {
    transform: translateY(-0.5vh);
    color: #ec3aab
} */


.navButton {
    align-self: center;
    background-color: #5b6468;
    background-image: none;
    background-position: 0 90%;
    background-repeat: repeat no-repeat;
    background-size: 4px 3px;
    border-radius: 15px 225px 255px 15px 15px 255px 225px 15px;
    border-style: solid;
    border-width: 2px;
    border-color: #efdad0;
    box-shadow: rgba(0, 0, 0, .2) 15px 28px 25px -18px;
    box-sizing: border-box;
    color: #efdad0;
    cursor: pointer;
    display: inline-block;
    font-family: montserrat;
    font-size: 1rem;
    line-height: 23px;
    outline: none;
    padding: .65rem;
    text-decoration: none;
    transition: all 235ms ease-in-out;
    border-bottom-left-radius: 15px 255px;
    border-bottom-right-radius: 225px 15px;
    border-top-left-radius: 255px 15px;
    border-top-right-radius: 15px 225px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin: 1em;
  }
  
  .navButton:hover {
    box-shadow: rgba(0, 0, 0, .3) 2px 8px 8px -5px;
    transform: translate3d(0, 2px, 0);
    color: #ec3aab;
  }
  
  .navButton:focus {
    box-shadow: rgba(0, 0, 0, .3) 2px 8px 4px -6px;
  }

@media (min-width:767px) {
  .plainLogo {
    display: none;
  }
}


  @media (max-width: 767px){

    .leftNav{
      display: none;
    }

    .centerNav{
      display: none;
    }
    
    .plainLogo{
      align-self: left;
      width: 250;
      height: 44.7;
      margin-left: 0;
    }

  }

  